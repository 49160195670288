import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ScrollButton from '../../utilities/ScrollButton';

import jesus from '../../images/jesus.svg';
import cloudLeft from '../../images/cloud-left.svg';
import cloudRight from '../../images/cloud-right.svg';
import bigCrosses from '../../images/big-crosses.svg';
import waveRed from '../../images/landing-page-wave-red.svg';
import waveBrown from '../../images/landing-page-wave-brown.svg';
import waveLightRed from '../../images/landing-page-wave-light-red.svg';
import basicWire from '../../images/basic-wire.svg';
import plan from '../../images/planspowiedzi.jpg';

import './Home.scss';
import { MdPadding } from 'react-icons/md';

const Home = () => {
  return (
    <div className='animation-wrapper'>
      <main className='default-container animation-section'>
        <Sidebar />
        <Navbar />

        <h1 className='landing-page-title'>
          <span className='first'>
            Parafia Chrystusa Dobrego Pasterza
          </span>{' '}
          w{' '}
          <span className='second'>
            Białych Błotach
          </span>
        </h1>

        <img
          src={cloudLeft}
          alt='chmura'
          className='landing-page-cloud left'
        />
        <img
          src={cloudRight}
          alt='chmura'
          className='landing-page-cloud right'
        />

        <img
          src={jesus}
          alt='Jezus'
          className='landing-page-jesus'
        />

        <img
          src={bigCrosses}
          alt='krzyże'
          className='landing-page-big-crosses'
        />

        <img
          src={waveLightRed}
          alt='jasno czerwona fala'
          className='landing-page-wave light-red'
        />
        <img
          src={waveRed}
          alt='czerwona fala'
          className='landing-page-wave red'
        />
        <img
          src={waveBrown}
          alt='brazowa fala'
          className='landing-page-wave brown'
        />

        <ScrollButton />
      </main>
      <section className='animation-section'>
        <div className='default-container'>
          <Navbar />
          <Sidebar />
          <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Ogłoszenia parafialne
            </h2>
            <h3 className='ogloszenia-title'>
              XXXII Niedziela Zwykła
            </h3>
            <h4 className='ogloszenia-date'>
              10 listopad 2024 r.
            </h4>
            <br />
            <ol
              type='1'
              start='1'
              className='ogloszenia-list'
            >
              <li>
                Z całego serca dziękujemy za
                ofiary składane na tacę tradycyjną
                i elektroniczną. Serdecznie
                dziękujemy za dodatkowe ofiary
                przekazane na cele gospodarczo –
                remontowe od rodzin: z ul. Ludowej
                500 zł., ul. Czekoladowej 100 zł,
                ul. Literackiej 100 zł., ul.
                Chlebowa 100 zł., ul. Kadetów 100
                zł. Z całego serca chciałbym w tym
                miejscu podziękować Państwu
                Teresie i Stanisławowi Zaorskim za
                wzięcie kosztów naprawy pieca na
                siebie. Dziękuję również Państwu
                Dorocie i Dariuszowi Stefańskim za
                ofiarowanie zmywarki do naszej
                remontowanej sali konferencyjnej.
                Z całego serca dziękuję! Naszą
                tacę remontową w tym miesiącu
                przesuniemy IV niedzielę czyli 24
                listopada. Serdeczne dziękujemy za
                wszelkie wsparcie!
              </li>
              <li>
                W poniedziałek 11 listopada
                obchodzimy Narodowe Święto
                Niepodległości. Serdecznie
                zapraszamy wszystkich do wspólnego
                świętowania w naszej gminie Białe
                Błota.
                <br />
                Program uroczystości przedstawia
                się następująco:
                <br />• 15.30 – uroczysta Msza
                Święta w intencji Ojczyzny
                sprawowana w kościele pw.
                Chrystusa Dobrego Pasterza w
                Białych Błotach.
                <br />• 16.15 – przemarsz spod
                kościoła pod Pomnik 100 – lecia
                odzyskania przez Polskę
                Niepodległości znajdujący się przy
                Szkole Podstawowej im. Mariana
                Rejewskiego w Białych Błotach.
                <br />• 16.30 – złożenie wieńców
                pod Pomnikiem przez wyznaczone
                delegacje
                <br />• 17.00 – przejście pod GCK
                gdzie rozpocznie się Bieg
                Niepodległości oraz część
                artystyczna i poczęstunek.
              </li>
              <li>
                W tym tygodniu w liturgii czcimy:
                <br />• w poniedziałek – św.
                Marcina z Tours, Biskupa;
                <br />• we wtorek – św. Jozafata,
                Biskupa;
                <br />• w środę – śś. Benedykta,
                Jana, Izaaka i Krystyna -
                Pierwszych Polskich Braci
                Męczenników;
              </li>
              <li>
                W Dzisiejszą niedzielę przeżywamy
                XVI Dzień Solidarności z Kościołem
                Prześladowanym w tym roku naszymi
                modlitwami i ofiarami do puszek
                wspieramy chrześcijan
                prześladowanych i cierpiących w
                Ziemi Świętej.
              </li>
              <li>
                Za tydzień w naszej wspólnocie
                będziemy gościć ks. Romana
                Sosnowskiego – proboszcza parafii
                pw. św. Brata Alberta w Występie
                oraz koordynatora Grup Modlitwy
                Św. Ojca Pio w naszej diecezji.
                Ksiądz Roman przez całą niedzielę
                głosić będzie słowo, które pozwoli
                nam przybliżyć osobę św. Ojca Pio
                oraz znaczenie powstałej w naszej
                parafii grupy modlitwy św. Ojca
                Pio. Wiemy, że ks. Roman buduje
                kościół dlatego po Mszach Świętych
                będziemy mogli wesprzeć budowę
                ofiarami do puszek na zakończenie
                Eucharystii.
              </li>
              <li>
                Dzieci klas III przygotowujące się
                do I Komunii Świętej oraz ich
                rodziców zapraszamy w przyszłą
                niedzielę po Mszy Świętej o godz.
                12.00 na spotkanie w kościele.
              </li>
              <li>
                Ksiądz Robert serdecznie zaprasza
                wszystkich ministrantów na zbiórkę
                w przyszłą sobotę 16 listopada o
                godz. 10.00 w kościele. Zapraszamy
                również chętnych chłopców, którzy
                pragną włączyć się do grupy
                ministrantów oraz do
                ministranckiej drużyny
                piłkarskiej.
                <br />
                Zapraszamy narzeczonych
                przygotowujących się do zawarcia
                sakramentu małżeństwa na katechezy
                przedślubne (kurs przedmałżeński)
                w formie warsztatowej wraz z
                poradnią rodzinną. Katechezy
                organizowane w naszej parafii pw.
                Chrystusa Dobrego Pasterza w
                Białych Błotach. Najbliższe
                katechezy odbędą się 15-16 i 22-23
                listopada 2024 roku. Spotkanie
                zaczynamy w piątek o godz. 17.00
                do około 20.30, a w sobotę zajęcia
                od 9.00 do około 13.00. Konieczne
                wcześniejsze zgłoszenia na adres:
                rodziny@diecezja.bydgoszcz.pl. W
                przypadku pytań proszę zwracać się
                do ks. Proboszcza.
              </li>
              <li>
                W poniedziałki około godz. 17.45
                modlitwy za wstawiennictwem św.
                Ojca Pio prowadzone przez Grupę
                Modlitwy Ojca Pio, a po wieczornej
                Mszy Świętej zapraszamy na
                spotkanie modlitewne „Odnowy w
                Duchu Świętym”, a we wtorki po
                wieczornej Mszy Świętej zapraszamy
                chętnych na otwarte spotkanie
                modlitewne prowadzone przez
                wspólnotę „Nowe Przymierze”. W
                czwartki około 18.30 adoracja
                Najświętszego Sakramentu z
                modlitwą do Chrystusa Dobrego
                Pasterza.
              </li>
              <li>
                W najbliższy czwartek 14 listopada
                o godz. 19.00 zapraszamy na wykład
                w naszej „Parafialnej Szkole
                Biblijnej”. Temat najbliższego
                spotkania – Czy przez gniew nie
                wejdę do nieba? (Mt 5,21-26).
                Wykład wraz z warsztatem prowadzi
                ks. dr Rafał Muzolf – biblista i
                proboszcz parafii Matki Bożej
                Bolesnej w Cielu.
              </li>
              <li>
                Za tydzień w środę  20 listopada
                około godz. 18.40 zapraszamy na
                kolejne otwarte spotkanie dla
                małżonków z różnym stażem.  Temat:
                Fundamenty naszego małżeństwa – 5
                prawd o małżeństwie.  Drodzy
                małżonkowie zapraszam do
                korzystania z możliwości
                wzbogacenia Waszych relacji. Warto
                wykorzystać możliwość, że takie
                spotkania są organizowane u nas.
                Warto przyjść, posłuchać, nic nie
                musicie mówić o sobie, a dużo
                możecie skorzystać dla swojego
                małżeństwa i rodziny. Spotkania
                poprowadzi Siostra Olga, pracująca
                z rodzinami w Ruchu Szensztackim.
                Spotkanie odbędzie się w
                Kawiarence Dobrego Pasterza.
              </li>
              <li>
                Podobnie jak w ubiegłym roku tak i
                w tym pragniemy zorganizować przy
                naszym kościele „Jarmark
                Bożonarodzeniowy”. Dnia 7 grudnia
                w godzinach popołudniowych dzień
                po „Mikołajkach”. Zostało
                przygotowanych już wiele pięknych
                świątecznych stroików itp.
                Wszystko przez cały rok powstawało
                w przemiłej atmosferze i ciężkiej
                pracy kreatywnej grupy
                artystycznej pod kierownictwem
                Pani Jolanty Kizelbach. Już
                dzisiaj całej grupie pań
                serdecznie dziękuję za trud i
                ofiarowany czas. Przy tej okazji
                chciałbym zaprosić chętne osoby do
                współpracy w przygotowaniu
                jarmarku. Zastanówcie się kto i w
                czym może pomóc. Część
                gastronomiczna, artystyczna itp.
                Serdecznie dziękuję i zapraszam do
                miłej współpracy.
              </li>
              <li>
                W przyszłym roku 2025 planujemy
                parafialną samolotową pielgrzymkę
                do Portugalii z Biurem
                Pielgrzymkowym Arcus w terminie
                22-29 września. W programie
                Fatima, Lizbona, Porto, Nazare,
                Santiago de Compostela.
                Przybliżony koszt pielgrzymki od
                osoby to 5450 zł. Szczegóły na
                plakacie w gablocie.
              </li>
              <li>
                Zachęcamy do czytania prasy
                katolickiej.
              </li>
              <li>
                W minionym tygodniu odeszli do
                Pana:
                <br />+ Zbigniew Kaszubowski, lat
                66, zam. ul. Gwarna,
                <br />+ Tadeusz Dziwnik, lat 70,
                zam. w Białych Błotach. Pogrzeb
                odbył się w sobotę w Turze,
                <br />+ Ryszard Adamski, lat 80,
                zam. w Bydgoszczy. W czasie
                choroby mieszkał również u
                najbliższych w Białych Błotach.
                Pogrzeb odbędzie się we wtorek o
                godz. 13.00 na cmentarzu w Białych
                Błotach.
                <br />
                …wieczny odpoczynek racz im dać
                Panie…
              </li>
            </ol>
            {/* <img
              src={plan}
              className='default-border planspowiedzi'
              alt='Plan Spowiedzi'
            ></img> */}
          </article>
          {/* <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Plan wizyty duszpasterskiej w roku
              2024
            </h2>
            <h4 className='ogloszenia-title koleda-center '>
              Rozpoczynamy w dni powszednie o
              godz. 16.00, w sobote o godz. 11:00.
            </h4>
            <br />
            <br />
            <br /> */}
          {/* <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>31.01 2023 r.</td>
                  <td>Gronowa, Czwartaków</td>
                  <td>
                    Czarnoleska, Chełmska, Cukrowa
                  </td>
                </tr>
              </tbody>
            </table> */}
          {/* <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 2, ul.
                    Berberysowa, ul. Bałkańska,
                    ul. Bagatela
                  </td>
                </tr>
                <tr>
                  <td>3.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 4 i blok
                    nr 6 oraz ul. Bracka, ul.
                    Bartnicza
                  </td>
                </tr>
                <tr>
                  <td>4.01, 16:00</td>
                  <td>
                    ul. Betonowa domki, ul.
                    Altanowa, ul. Alibaby, ul.
                    Szubińska nr 4-12, ul. Biała,
                    ul. Betonowa 1a
                  </td>
                </tr>
                <tr>
                  <td>5.01, 16:00</td>
                  <td>
                    ul. Azalowa, ul. Ametystowa,
                    ul. Asfaltowa, ul. Jaśminowa,
                    ul. Leśna, ul. Konwaliowa, ul.
                    Szafirowa, ul. Tulipanowa, ul.
                    Żonkilowa, ul. Różana
                  </td>
                </tr>
                <tr>
                  <td>8.01, 16:00</td>
                  <td>
                    ul. Chmielna, ul. Chmielarska,
                    ul. Czarnoleska, ul. Chełmska,
                    ul. Cukrowa
                  </td>
                </tr>
                <tr>
                  <td>9.01, 16:00</td>
                  <td>
                    ul. Chlebowa 7-83, ul.
                    Iglasta, ul. Izoldy, ul.
                    Cedrowa, ul. Cyprysowa
                  </td>
                </tr>
                <tr>
                  <td>10.01, 16:00</td>
                  <td>
                    ul. Chlebowa 2-76, ul.
                    Gronowa, ul. Czwartaków
                  </td>
                </tr>
                <tr>
                  <td>11.01, 16:00</td>
                  <td>
                    ul. Chlebowa 85-105, ul.
                    Boruty, ul. Zawiła, ul.
                    Czeremchy, ul. Cynowa, ul.
                    Daliowa
                  </td>
                </tr>
                <tr>
                  <td>12.01, 16:00</td>
                  <td>
                    ul. Czysta, ul. Bajeczna, ul.
                    Czekoladow
                  </td>
                </tr>
                <tr>
                  <td>13.01, 11:00</td>
                  <td>
                    ul. Chudoby, ul. Cukiernicza,
                    ul. Ulana
                  </td>
                </tr>
                <tr>
                  <td>15.01, 16:00</td>
                  <td>ul. Gontowa, ul. Gwarna</td>
                </tr>
                <tr>
                  <td>16.01, 16:00</td>
                  <td>
                    ul. Drzewna, ul. Czajcza, ul.
                    Zeusa
                  </td>
                </tr>
                <tr>
                  <td>17.01, 16:00</td>
                  <td>
                    ul. Duńska, ul. Ezopa, ul.
                    Daleka, ul. Żaków, ul. Cała
                  </td>
                </tr>
                <tr>
                  <td>18.01, 16:00</td>
                  <td>
                    ul. Guliwera, ul. Modra, ul.
                    Letnia, ul. Moczary
                  </td>
                </tr>
                <tr>
                  <td>19.01, 16:00</td>
                  <td>
                    ul. Ostróżki, ul. Szubińska 87
                    E, ul. Przemysłowa, ul.
                    Wierzbowa, ul. Zacisze, ul.
                    Kapliczna, ul. Szubińska
                  </td>
                </tr>
                <tr>
                  <td>20.01, 11:00</td>
                  <td>
                    ul. Centralna 2-66 i 9-41a,
                    ul. Cisowa, ul. Centralna 47 i
                    68 do 110 i 113
                  </td>
                </tr>
                <tr>
                  <td>22.01, 16:00</td>
                  <td>
                    ul. Jaracza, ul. Czerska
                    57-111, ul. Forteczna, ul.
                    Owcza, ul. Feniksa, ul.
                    Jutrzenki 1 i 1a, ul. Judyma
                  </td>
                </tr>
                <tr>
                  <td>23.01, 16:00</td>
                  <td>
                    ul. Czerska 2-36, ul. Czerska
                    1-55
                  </td>
                </tr>
                <tr>
                  <td>24.01, 16:00</td>
                  <td>
                    ul. Czahary od 2-34 i 3-27,
                    ul. Sielska, ul. Czahary(od
                    numerów końcowych), ul.
                    Sobótki, ul. Parkowa
                  </td>
                </tr>
                <tr>
                  <td>25.01, 16:00</td>
                  <td>
                    ul. Literacka, ul. Olchowa
                  </td>
                </tr>
                <tr>
                  <td>26.01, 16:00</td>
                  <td>
                    ul. Hodowlana, ul. Rzepichy,
                    ul. Ludowa
                  </td>
                </tr>
                <tr>
                  <td>27.01, 11:00</td>
                  <td>
                    ul. Hippiczna - nr
                    nieparzyste, ul. Hippiczna –
                    nr parzyste
                  </td>
                </tr>
                <tr>
                  <td>29.01, 16:00</td>
                  <td>
                    ul. Barwinkowa, ul. Epokowa,
                    ul. Barwinkowa 1-29 i 26-28c
                  </td>
                </tr>
                <tr>
                  <td>30.01, 16:00</td>
                  <td>
                    ul. Goplany, ul. Herbowa, ul.
                    Hebanowa, ul. Dworska, ul.
                    Arlekina, ul. Alpejska, ul.
                    Bazaltowa
                  </td>
                </tr>
                <tr>
                  <td>
                    &nbsp;31.01, 16:00&nbsp;
                  </td>
                  <td>
                    ul. Niedzielna, ul. Miła, ul.
                    Lechicka, ul. Młoda, ul.
                    Turkusowa, ul. Piesza, ul.
                    Mokra
                  </td>
                </tr>
                <tr>
                  <td>1.02, 16:00</td>
                  <td>
                    ul. Temidy, ul. Jantarowej 8 i
                    10, ul. Lutników, ul.
                    Ogniskowa, ul. Trawiasta, ul.
                    Nizinna
                  </td>
                </tr>
                <tr>
                  <td>3.02, 11:00</td>
                  <td>
                    ul. Żeńców, ul. Kadetów, ul.
                    Jantarowa, ul. Uroczysko, ul.
                    Barycka
                  </td>
                </tr>
                <tr>
                  <td>5.02, 16:00</td>
                  <td>
                    ul. Popiela, ul. Moczary, ul.
                    Reduty, ul. Jutrzenki
                  </td>
                </tr>
              </tbody>
            </table> */}
          {/* </article> */}
          <img
            className='basic-wire'
            src={basicWire}
            alt='fala'
          />
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
