import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import basicWire from '../../images/basic-wire.svg';

import './Intencje.scss';

const Intencje = () => {
  return (
    <div className='animation-wrapper'>
      <div className=' animation-section'>
        <main className='default-container'>
          <Navbar />
          <Sidebar />
          <section className='default-content-wrapper-without-img'>
            <h1 className='subpage-title-center'>
              Intencje Mszalne
            </h1>
            <article className='flexbox-wrapper intencje-flexbox'>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 10.11.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Uroczystość Najśw. Trójcy
                  </h4> */}
                  <br />
                  <b>8:00</b> + Kazimiera
                  Pawlikowska (1 rocz. śm.) oraz
                  Henryk, Tadeusz i Jan
                  Pawlikowscy
                  <br />
                  <b>10:00</b> + Franciszek Zenisz
                  (7 rocz. śm.) i Urszula Lesińska
                  ( z okazji ur.)
                  <br />
                  <b>10:00</b> + Marian i Zofia
                  Olejnik oraz bliskich zmarłych o
                  dar Nieba i wieczne zbawienie
                  <br />
                  <b>12:00</b> + Maria Korytowska
                  <br />
                  <b>12:00</b> + Bronisław
                  Chrustowski, rodziców z obojga
                  stron oraz o Boże błog. dla
                  całej rodziny
                  <br />
                  <b>18:00</b> + Natalia Kochaniec
                  – int. od pracowników firmy
                  Inter Rest
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 17.11.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Uroczystość Najśw. Trójcy
                  </h4> */}
                  <br />
                  <b>8:00</b> + Roman (39 rocz.
                  śm.), Józef (7 rocz. śm.) zm. z
                  rodziny Bednarek, Bembnista i
                  dusze w czyśćcu cierpiące
                  <br />
                  <b>8:00</b> + Adam (6 greg.)
                  <br />
                  <b>10:00</b> + Roman Migawa (13
                  rocz. śm.), Eugeniusz Cyganek i
                  zm. z rodzin obu stron i dusze w
                  czyśćcu cierpiące
                  <br />
                  <b>10:00</b> + Zbigniew Wojdylak
                  (30 rocz. śm.) i zm. z rodzin
                  obojga stron
                  <br />
                  <b>12:00</b> Rezerwacja
                  <br />
                  <b>18:00</b> + Krystyna Czop (3
                  rocz. śm.)
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Poniedziałek 11.11.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Św. Marcina Tours, 106
                    Rocznica Odzyskania
                    Niepodległości
                  </h4>
                  <br />
                  <b>8:00</b> + Wanda Ból (3 rocz.
                  śm.) i Władysław Ból, zm. z
                  rodziny i dusze w czyśćcu
                  cierpiące
                  <br />
                  <b>15:30</b> W intencji Ojczyzny
                  <br />
                  <b>18:00</b> + Daniela Stańczak
                  (1 rocz. śm.)
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Wtorek 12.11.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Św. Jozafata
                  </h4>
                  <br />
                  <b>8:00</b> + Adam (1 greg.)
                  <br />
                  <b>18:00</b> O Boże błog, opiekę
                  MBożej i dobre zdrowie za
                  przyczyną św. O. Pio dla
                  kochanej Mamy, Teściowej i Babci
                  Grażynki z ok. 70 rocz. ur.
                  <br />
                  <b>18:00</b> W intencji Panu
                  Bogu wiadomej
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Środa 13.11.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Św. Polskich Braci Męczenników
                  </h4>
                  <br />
                  <b>8:00</b> + Adam (2 greg.)
                  <br />
                  <b>16:00</b> W intencji Nowenny
                  do MB Nieust. Pomocy:
                  <br />+ w int. zmarłych członków
                  Wspólnoty Żywego Różańca oraz
                  ich bliskich
                  <br />+ Stanisław Maraś (1 rocz.
                  śm.)
                  <br />+ Łukasz Szatkowski ( z
                  okazji ur.)
                  <br />+ Mieczysław Błędowski –
                  int. od Zakładu Pogrz. z Białych
                  Błot
                  <br />+ Zbigniew Kaszubowski –
                  int. od Zakładu Pogrz. z Białych
                  Błot
                  <br />
                  <b>18:00</b> + Stanisław Brzykcy
                  – int. od żony i dzieci
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Czwartek 14.11.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Zakończenie oktawy Bożego
                    Ciała
                  </h4> */}
                  <br />
                  <b>8:00</b> + Adam (3 greg.)
                  <br />
                  <b>18:00</b> + Jadwiga Klesińska
                  (2 rocz. śm.) i zm. z rodziny
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Piątek 15.11.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Św. Alberta Wielkiego
                  </h4>
                  <br />
                  <b>8:00</b> + Adam (4 greg.)
                  <br />
                  <b>16:00</b> + Tomasz Pikul –
                  int. od kuzyna Marcina z Tarnowa
                  <br />
                  <b>18:00</b> + Stanisława(k) i
                  Bronisław Dahms
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Sobota 16.11.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Święto pośw. rzymskich bazylik
                    św.Ap. Piotra i Pawła
                  </h4>
                  <br />
                  <b>8:00</b> + Adam (5 greg.)
                  <br />
                  <b>18:00</b> + Edmund Flemmig
                  <br />
                  <b>18:00</b> Rezerwacja
                </div>
              </div>
            </article>
            <img
              className='basic-wire'
              src={basicWire}
              alt='fala'
            />
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Intencje;
